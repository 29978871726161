import { Container, ContainerSucces } from './styles'
import { useForm, ValidationError } from '@formspree/react'
import { toast, ToastContainer } from 'react-toastify'
import { useEffect, useState, ChangeEvent, FormEvent } from 'react'
import validator from 'validator'
import axios from 'axios';

interface FormData {
  MessageTitle: string;
  Message: string;
  Email: string;
  GuestName: string;
  Phone?: string;
}

interface FormErrors {
  MessageTitle?: string;
  Message?: string;
  Email?: string;
  GuestName?: string;
  Phone?: string;
}

export function Form() {
  const [formData, setFormData] = useState<FormData>({
    MessageTitle: '',
    Message: '',
    Email: '',
    GuestName: '',
    Phone: '',
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState('');

  const validate = ():boolean=>{
    let tempErrors:FormErrors = {};
    tempErrors.MessageTitle = formData.MessageTitle ?'': 'Message Title is required';
    tempErrors.Message = formData.Message ? '' : 'Message is required';
    tempErrors.Email = /\S+@\S+\.\S+/.test(formData.Email) ? '' : 'Email is not valid.';
        tempErrors.GuestName = formData.GuestName ? '' : 'Guest Name is required.';
        if (formData.Phone) {
            const phoneRegex = /^(\(\d{3}\)\s?|\d{3}[-.\s]?)\d{3}[-.\s]?\d{4}$/;
            tempErrors.Phone = phoneRegex.test(formData.Phone) ? '' : 'Phone number is not valid.';
        }
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void=>{
    const {name, value} = e.target;
    // console.log(`Changing field ${name} to ${value}`)
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (validate()) {
      setIsLoading(true)
        try {
            const response = await axios.post(`https://ihxzx8eie2.execute-api.us-east-1.amazonaws.com/prod/message`, formData);
            // setResponseMessage('Message processed successfully!');
            console.log(response.data)
            if(response.status===200){
              setSubmissionSuccess(true)
            }
            
        } catch (error) {
            setResponseMessage('Error processing message.');
            console.error('There was an error!', error);
        }
    } else {
        setResponseMessage('Please correct the errors in the form.');
    }
};


  if (submissionSuccess) {
    return (
      <>
        <h3>Thanks for getting in touch!</h3>
        <button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          Back to the top
        </button>
        <ToastContainer />
        </>
    );
  }

  return (
    <Container>
      <h2>Get in touch using the form</h2>
      <form onSubmit={handleSubmit}>
        <input
          placeholder="Message Title*"
          id="messageTitle"
          type="text" 
          name="MessageTitle" 
          value={formData.MessageTitle} 
          onChange={(handleChange)} 
          required 
        />
        {errors.MessageTitle && <span>{errors.MessageTitle}</span>}
        <input
          placeholder="Email*"
          id="email"
          type="email"
          name="Email"
          value={formData.Email}
          onChange={handleChange}
          required
        />
        {errors.Email && <span>{errors.Email}</span>}
        <input
          placeholder="Guest Name*"
          id="guestName"
          type="text"
          name="GuestName"
          value={formData.GuestName}
          onChange={handleChange}
          required
        />
        {errors.GuestName && <span>{errors.GuestName}</span>}
        <textarea
          required
          placeholder="Send a message to get started.*"
          id="message"
          name="Message"
          value={formData.Message}
          onChange={handleChange}
        />
        {errors.Message && <span>{errors.Message}</span>}
        <input
          placeholder="Phone (Optional)"
          id="phone"
          type="text"
          name="Phone"
          value={formData.Phone}
          onChange={handleChange}
        />
        <button
          type="submit"
          disabled={isLoading || Object.values(errors).some((error) => error)}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      <ToastContainer />
      {responseMessage&& <p>{responseMessage}</p>}
    </Container>
  );
}
