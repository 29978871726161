import { Container } from "./styles";
import ampArticle from "../../assets/AWS Amplify.pdf";
import amp from "../../assets/amplify.png";
import ScrollAnimation from "react-animate-on-scroll";

export function Article() {
  return (
    <Container id="about">
      <div className="about-text">
        <h2>Blog</h2>
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>AWS Amplify</h2>
        <img src={amp} alt="amplify" />
        <p>Build full-stack web and mobile apps in hours. Easy to start, easy to scale.</p>
        <p>
AWS Amplify is a development framework and a web hosting service to build and deploy applications.
Amplify is everything you need to build full-stack web and mobile apps on AWS. Build and host your frontend, add features like auth and storage, connect to real-time data sources, deploy, and scale to millions of users.
</p>
<a href={ampArticle} target="_blank" rel="noopener noreferrer" className="button">
            Read more 
          </a>
        </ScrollAnimation>
        
        
      </div>
      
    </Container>
  )
}
